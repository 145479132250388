<template>
  <div>
    <h3>Kedves Párok!</h3>

    <p>
      Szeretettel hívunk bennetek idén is a Házasság Hete eseményeire "<i>Bennünk a létra</i>" mottóval. Úgy tűnik, öregednek a szervezők, mert romantikus kedvükben 😉 
      egy romantikus túrára hívnak benneteket a kapcsolatotok kezdetére. Erről további részleteket a <router-link to="/trials">"Itt kezdődött"</router-link> menüpont 
      alatt találtok.
    </p>

    <p>
      Többen közületek az előző években is részt vettetek a programokon, tudjátok, hogy naprakész információkkal segítünk titeket e-mailen keresztül. Ha 
      eddig még nem adtátok meg elérhetőségeteket, kérünk osszátok meg velünk a <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> 
      címre küldött e-maillel.
    </p>

    <p>
      A Házasság Hetére idén is mindenkit várunk, nemcsak házaspárokat, hanem mindazokat, akik hisznek az életre szóló elkötelezett párkapcsolat értékében.
       Fiatal házasok és nagyon rég óta fiatal házasok is gyertek!!! Tudjuk, sokaknak nehéz megoldani a gyerekvigyázást, amikor el szeretnének jutni valamelyik
        programra. Tudunk segíteni, ez ügyben keressétek Hollai Zsófit a +36204404580-as telefonszámon!
    </p>

    <p>
      Adományaitokat a programok költségeinek fedezésére köszönettel fogadjuk.
    </p>
    

    <p class="right-align">HH Team</p>

    <h2>Közös imádság</h2>
    <div class="list-wrapper">
      <p>
        <strong>Nagymaros, Római Katolikus Templom</strong>
        <br> február 5. hétfő este 19.00
      </p>
    </div>

    <h2>Előadás</h2>
    <div class="list-wrapper">
      <p>
        <strong>Istenkapcsolatunk a házasságban:</strong>
        <br>Janka Ferenc görögkatolikus pap és felesége
      </p>
      <p>
        <strong>Nagymaros, Római Katolikus Templom</strong>
        <br> február 7. szerda este 19.00
      </p>
    </div>

    <h2>Házassági fogadalom megújítása</h2>
    <div class="list-wrapper">
      <p>
        <strong>Nagymaros, Római Katolikus Templom</strong>
        <br> február 10. szombat este 18.30, szentmise
      </p>
      <p>
        <strong>Nagymaros, Református Templom</strong>
        <br> február 11. vasárnap délelőtt 10.00, istentisztelet
      </p>
    </div>

    <h2>Gyertyafényes vacsora</h2>
    <div class="list-wrapper">
      <p>
        Idén is szervezünk gyertyafényes vacsorát, kérünk jelezzétek részvételi szándékotokat a 
        <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> címre küldött e-maillel. És ne feledjétek elhozni a feladatlapon említett
        különleges tárgyat sem.
      </p>
      <p>
        <strong>Nagymaros, Szent Márton Közösségi Ház</strong>
        <br> február 10. szombat este 20.00
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'main'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
